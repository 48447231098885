import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Form from './Form';
import Footer from './Footer';
import '../App.css';
import donateImage from '../assets/elephant.png';

const DonationVolunteer = () => {
    return (
        <HelmetProvider>
        <Helmet>
          <title>CCRC - Donate</title>
          <meta name="description" content="Welcome to the Camden County Republican Club. Join us to promote Republican values in Camden County, NJ." />
          <meta name="keywords" content="Camden County, Republican Club, Republican values, Camden County NJ, politics, Republican, committee" />
        </Helmet>
        <div className="main-content">
            <div className="card-container">
                <div className="card">
                    <h2>Join or Volunteer</h2>
                    <p>Join the Club or Help us make phone calls, knock doors, or text voters!</p>
                    <Form />
                </div>
                <div className="card">
                    <h2>Click to Donate!</h2>
                    <div className="image-container">
                        <a href="https://www.anedot.com/your-anedot-page" target="_blank" rel="noopener noreferrer">
                            <img src={donateImage} alt="Donate" className="donate-image" />
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </HelmetProvider>
    );
};

export default DonationVolunteer;
