import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from './Footer';
import '../App.css';
import donateImage from '../assets/elephant.png'; 
import volunteerImage from '../assets/hands.png'; 

const Home = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Camden County Republican Club</title>
        <meta name="description" content="Welcome to the Camden County Republican Club. Join us to promote Republican values in Camden County, NJ." />
        <meta name="keywords" content="Camden County, Republican Club, Republican values, Camden County NJ, politics, Republican, committee" />
      </Helmet>
      <div className="main-content">
        <div className="card-container">
          <div className="card">
            <h1>CCRC Mission Statement</h1>
            <p>"Our mission is to promote the values of the Republican Party throughout Camden County, NJ with selfless service to the community. We seek to protect the US Constitution and New Jersey Constitution by promoting candidates that uphold those values."</p>
            <h1>Come Visit Us!</h1>
            <p>We are at HQ every 1st, 2nd, and 4th Wednesday. Our address is 10 Shoppers Lane Blackwood, NJ. We hope to see you soon!</p>          
          </div>
          <div className="card">
            <div className="image-container">
              <p>Join Today!</p>
              <Link to="/donation-volunteer">
                <img src={volunteerImage} alt="Volunteer/Donate" />
              </Link>
            </div>
            <div className="image-container">
              <p>Donate Online!</p>
              <a href="https://www.anedot.com/your-anedot-page" target="_blank" rel="noopener noreferrer">
                <img src={donateImage} alt="Donate" />
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Home;
