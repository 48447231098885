import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css'; 
import Home from './components/Home';
import VotingInfo from './components/VotingInfo';
import Municipalities from './components/Municipalities';
import DonationVolunteer from './components/DonationVolunteer';
import PrivacyPolicy from './components/PrivacyPolicy';
import headerImage from './assets/usa_banner2.jpg'; 
import logoImage from './assets/Camden_County_Republican_Club_Logo.png'; 

class ErrorBoundary extends React.Component {
    componentDidCatch(error, errorInfo) {
        console.error('Error caught in error boundary:', error, errorInfo);
    }

    render() {
        return this.props.children;
    }
}

function App() {
    return (
        <HelmetProvider>
            <ErrorBoundary>
                <Router>
                    <div>       
                        <div className="App">
                            <header className="App-header">
                                <img src={headerImage} alt="Header" className="header-image" />
                                <img src={logoImage} alt="Logo" className="logo-image" />
                                <nav className="navbar">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/donation-volunteer">Get Involved</Link></li>
                                        <li><Link to="/voting-info">Voting Info</Link></li>
                                        <li><Link to="/municipalities">Municipalities</Link></li>
                                    </ul>
                                </nav>
                            </header>
                            <main>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/voting-info" element={<VotingInfo />} />
                                    <Route path="/municipalities" element={<Municipalities />} />
                                    <Route path="/donation-volunteer" element={<DonationVolunteer />} />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                </Routes>
                            </main>
                        </div>
                    </div> 
                </Router>
            </ErrorBoundary>
        </HelmetProvider>
    );
}

export default App;
