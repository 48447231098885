import React from 'react';
import '../App.css'; 

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>CCRC Privacy Policy</h1>
            <p><strong>Effective Date: 8/6/2024</strong></p>

            <p>
                Camden County Republican Club ("we," "us," or "our") is committed to protecting the privacy of visitors and users ("you" or "your") of our political campaign website. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information through our website. By accessing and using our website, you consent to the terms of this Privacy Policy.
            </p>

            <h2>1. Information We Collect:</h2>
            <p><strong>a. Personal Information:</strong> We may collect personal information you voluntarily provide to us, such as your name, email address, postal address, phone number, and any other information you submit through our website's forms.</p>
            <p><strong>b. Text Messaging Opt-In Data:</strong> If you choose to opt-in to receive text messages from us, we may collect your phone number and related data required for text messaging services.</p>
            <p><strong>c. Automatically Collected Information:</strong> When you visit our website, we may automatically collect certain information about your device, browser, and usage patterns. This information may include IP addresses, cookies, and other tracking technologies.</p>

            <h2>2. Use of Information:</h2>
            <p><strong>a.</strong> We may use the personal information you provide to us for the following purposes:
                <ul>
                    <li>To communicate with you, respond to your inquiries, and provide information about our campaign.</li>
                    <li>To send you updates, newsletters, and other campaign-related information.</li>
                    <li>To analyze and improve our website's performance, content, and user experience.</li>
                    <li>To comply with legal obligations and enforce our rights and agreements.</li>
                </ul>
            </p>
            <p><strong>b.</strong> Text Messaging Opt-In Data: Your phone number and related data collected for text messaging services will only be used to send you campaign-related text messages and updates.</p>

            <h2>3. Sharing of Information:</h2>
            <p><strong>a.</strong> We will not share, sell, rent, or disclose your personal information to any third parties, except as described in this Privacy Policy or when required by law.</p>
            <p><strong>b.</strong> Text Messaging Opt-In Data: We will not share or sell your text messaging opt-in data, consent, or related personal information with any third parties, unless required by law.</p>

            <h2>4. Data Security:</h2>
            <p>We take reasonable measures to protect the security of your personal information and employ industry-standard security technologies to safeguard it. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

            <h2>5. Third-Party Services:</h2>
            <p>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third parties. We encourage you to review the privacy policies of those third parties when accessing their websites or services.</p>

            <h2>6. Children's Privacy:</h2>
            <p>Our website is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13 without parental consent, we will take steps to remove such information from our records.</p>

            <h2>7. Updates to this Privacy Policy:</h2>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be effective immediately upon posting of the revised Privacy Policy on our website. We encourage you to review this page periodically for the latest information on our privacy practices.</p>

            <h2>8. Contact Us:</h2>
            <p>If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact us at: <a href="mailto:chair@ccrepublicanclub.com">chair@ccrepublicanclub.com</a></p>
        </div>
    );
};

export default PrivacyPolicy;
