import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>Paid for by Camden County Republican Club</p>
            <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
        </footer>
    );
};

export default Footer;
