import React, { useState } from 'react';
import axios from 'axios';

const Form = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        consent: false
    });
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.consent) {
            alert('You must agree to the consent to submit the form.');
            return;
        }
        try {
            const response = await axios.post('https://ccrepublicanclub.com/submit-form', formData);
            setSubmitted(true);
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
                consent: false
            });
        } catch (error) {
            console.error('There was an error submitting the form!', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {submitted && (
                <div style={{ color: 'green' }}>
                    <p>Thank you for your interest in the Camden County Republican Club. We love our supporters, so feel free to visit us when we are volunteering at headquarters. Please fill out the form below, and join the mail list to stay updated on what we've been doing. Whether you're looking to get involved as a volunteer, would like to become a member, or just want to learn more about our organization, we would love to hear from you. Let's work together to create a brighter future for our country.</p>
                </div>
            )}
            <div>
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label htmlFor="phone">Phone Number:</label>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label htmlFor="message">Message:</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <input
                    type="checkbox"
                    id="consent"
                    name="consent"
                    checked={formData.consent}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="consent">
                    By providing your telephone number and email, you consent to receive calls and text messages from the CCRC, including pre-recorded messages and via automated methods. Msg & data rates may apply. Msg frequency may vary. Reply “STOP” to opt-out & “HELP” for help. View our Privacy Policy for more info.
                </label>
            </div>
            <button type="submit">Submit</button>
        </form>
    );
};

export default Form;
