import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../App.css';
import Footer from './Footer';

const VotingInfo = () => {
    return (
        <HelmetProvider>
        <Helmet>
          <title>CCRC - Donate</title>
          <meta name="description" content="Welcome to the Camden County Republican Club. Join us to promote Republican values in Camden County, NJ." />
          <meta name="keywords" content="Camden County, Republican Club, Republican values, Camden County NJ, politics, Republican, committee" />
        </Helmet>
        <div className="main-content">
            <div className="card-container">
            <section className="card">
                    <h2>Voting in Person</h2>
                    <p>
                        <a href="https://www.camdencounty.com/service/voting-and-elections/polling-places/" target="_blank" rel="noopener noreferrer">
                            Polling Places Information
                        </a>
                    </p>
                </section>
                <section className="card">
                    <h2>Voter Registration</h2>
                    <p>
                        Register to Vote Easily Online <br />
                        <a href="https://voter.svrs.nj.gov/register" target="_blank" rel="noopener noreferrer">
                            Register Here!
                        </a>
                    </p>
                </section>
                <section className="card">
                    <h2>Vote by Mail Instructions</h2>
                    <p>
                        Voting in New Jersey is easier than ever with the new Vote By Mail law. A recent change to the NJ law now allows you to request ballots for all future elections. Apply once and receive a ballot for every election without having to re-apply.
                    </p>
                    <p>
                        The State of New Jersey is providing Vote by Mail drop boxes for each county. There will be 29 locations throughout the county that will be available 24/7 through the close of polls. The locations will be well lit and be monitored by video surveillance cameras.
                    </p>
                    <ul>
                        <li>Audubon Boro: Audubon Municipal Building, 606 W. Nicholson Rd.</li>
                        <li>Barrington: Barrington Municipal Building, 229 Trenton Avenue</li>
                        <li>Bellmawr: Bellmawr Municipal Building, 21 East Browning Road</li>
                        <li>Berlin Twp: Berlin Township Municipal Building, 135 Rt. 73 South</li>
                        <li>Berlin Borough: Berlin Borough Municipal Building, 59 South White Horse Pike</li>
                        <li>Camden City: Pantry 1 Food Mart, 1310 Collings Rd</li>
                        <li>Camden City: Kroc Center, 1865 Harrison Avenue</li>
                        <li>Camden City: County Administration Building (Market Street Entrance), 600 Market St.</li>
                        <li>Camden City: Virtua Emergency Department, 1000 Atlantic Ave</li>
                        <li>Cherry Hill: Camden Co. College-Rohrer Campus, 1889 Marlton Pike East</li>
                        <li>Cherry Hill: Cherry Hill Municipal Building, 820 Mercer St.</li>
                        <li>Clementon: Clementon Fire-Rescue, 165 Gibbsboro Road</li>
                        <li>Collingswood: Municipal Building, Rear Entrance/Living Room, 678 Haddon Avenue</li>
                        <li>Gloucester City: Gloucester City Municipal Building, 512 Monmouth Street</li>
                        <li>Gloucester Twp: Gloucester Twp. Municipal Building, 1261 Chews Landing Rd.</li>
                        <li>Gloucester Twp: Camden County College, Blackwood Campus, Taft Hall Area - 200 College Dr.</li>
                        <li>Gloucester Twp: Elections & Archive Center, 100 University Court</li>
                        <li>Haddonfield: Haddonfield Borough Hall, 242 Kings Highway East</li>
                        <li>Haddon Heights: Service Operations Facility, 514 West Atlantic Avenue</li>
                        <li>Haddon Twp: Haddon Township Municipal Building, 135 Haddon Ave.</li>
                        <li>Lindenwold: Lindenwold Municipal Building, 15 N. White Horse Pike</li>
                        <li>Pennsauken: Pennsauken Municipal Building, 5605 N. Crescent Blvd.</li>
                        <li>Pine Hill: Pine Hill Municipal Building, 45 West 7th Avenue</li>
                        <li>Runnemede: Runnemede Municipal Building, 24 N. Black Horse Pike</li>
                        <li>Somerdale: Somerdale Municipal Building, 105 Kennedy Blvd</li>
                        <li>Stratford: Rowan Medical Building, 42 East Laurel Road</li>
                        <li>Voorhees: Voorhees Town Center, 2400 Voorhees Town Center</li>
                        <li>Waterford: Waterford Municipal Building, 2131 Auburn Avenue</li>
                        <li>Winslow Township: Winslow Municipal Building, 125 South Rt. 73</li>
                    </ul>
                    <p>
                        BALLOTS CAN ALSO BE DROPPED IN-PERSON AT: <br />
                        Camden County Board of Elections, Elections and Archive Center, 100 University Court, Blackwood, NJ 08012 <br />
                        Monday to Friday, 8:30 AM to 4:30 PM <br />
                        Election Day, 6 AM to 8 PM
                    </p>
                    <p>
                        If you would like to hand-deliver your ballot to the Board of Elections, you will be the bearer of that ballot and identification is required. If someone else transports your ballot, they must complete the Bearer portion of the Green Return Envelope in your presence before delivering to the Board office.
                    </p>
                </section>
                <section className="card">
                    <h2>Early Voting Instructions</h2>
                    <p>
                        EARLY VOTING Make a Plan to Vote! Early Voting is the newest option voters have in order to cast their ballot for the Primary Election.
                    </p>
                    <ul>
                        <li>All Camden County registered voters can appear at any of the locations. Voters are not assigned a specific location. Ballots for all Camden County municipalities are available at each location.</li>
                        <li>Early Voting will be conducted on voting machines; however, the machines are different from the Election Day machines. These new machines have two parts: a ballot marking device and a scanning tabulator. The ballot marking device has an electronic touchscreen that allows the voters to choose their candidates/question responses and have them printed on a paper ballot. The voter will then immediately insert the voted paper ballot in the separate scanning tabulator to cast the ballot in private.</li>
                        <li>Any voter that was sent a Vote by Mail ballot cannot vote in a machine during Early Voting. These voters, however, can cast a provisional ballot. The provisional ballot will be counted once all timely Vote by Mail Ballots arrive and it is verified that the voter did not cast two ballots.</li>
                    </ul>
                    <p>
                        DATES & TIMES <br />
                        The Early Voting locations are open from: <br />
                        June 2: 10am to 8pm <br />
                        June 3: 10am to 8pm <br />
                        June 4: 10am to 6pm
                    </p>
                    <p>
                        LOCATIONS <br />
                        Gloucester Township - Elections & Archive Center, 100 University Court, Blackwood (Warehouse, Side Entrance) <br />
                        Camden City - Rowan University, 129 N. Broadway, Camden (Parking at PNC Bank and street parking available) <br />
                        Collingswood - Collingswood Senior Center, 30 W. Collings Ave, Collingswood (Large Living Room on First Floor) <br />
                        Merchantville - Merchantville Community Center, 212 Somerset Street (Lower floor, ramp access from side of building) <br />
                        Runnemede - Harry Williams Community Center, 2 Broadway, Runnemede (Senior Room) <br />
                        Winslow - Bud Duble Community Center, 33 Cooper Folly Rd, Atco <br />
                        Voorhees - Lion’s Lake Community Center, 101 Dutchtown Rd., Voorhees
                    </p>
                </section>
            </div>
            <Footer />
        </div>
        </HelmetProvider>
    );
};

export default VotingInfo;

