import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '../App.css';
import Footer from './Footer';

const Municipalities = () => {
    const municipalities = [
        'Audubon',
        'Audubon Park',
        'Barrington',
        'Bellmawr',
        'Berlin',
        'Berlin Township',
        'Brooklawn',
        'Camden',
        'Cherry Hill',
        'Chesilhurst',
        'Clementon',
        'Collingswood',
        'Gibbsboro',
        'Gloucester City',
        'Gloucester Township',
        'Haddon Heights',
        'Haddon Township',
        'Haddonfield',
        'Hi-Nella',
        'Laurel Springs',
        'Lawnside',
        'Lindenwold',
        'Magnolia',
        'Merchantville',
        'Mount Ephraim',
        'Oaklyn',
        'Pennsauken Township',
        'Pine Hill',
        'Pine Valley',
        'Runnemede',
        'Somerdale',
        'Stratford',
        'Tavistock',
        'Voorhees Township',
        'Waterford Township',
        'Winslow Township',
        'Woodlynne'
    ];

    return (
        <HelmetProvider>
        <Helmet>
          <title>CCRC - Donate</title>
          <meta name="description" content="Welcome to the Camden County Republican Club. Join us to promote Republican values in Camden County, NJ." />
          <meta name="keywords" content="Camden County, Republican Club, Republican values, Camden County NJ, politics, Republican, committee" />
        </Helmet>
        <div className="main-content">
            <div className="card-container">
            <div className="card">
            <h1>Municipalities in Camden County</h1>
            </div>
                <div className="card-container">
                    {municipalities.map((municipality, index) => (
                        <div key={index} className="card">
                            {municipality}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    </HelmetProvider>
    );
};

export default Municipalities;


